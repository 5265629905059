export const environment = {
    production: true,
    testMode: false,
    ENV: `production`,
    API: `https://api.amopportunities.org`,
    GRAPHQL_URL: `https://api.amopportunities.org/graphql`,
    WEBSOCKET_URL: `wss://api.amopportunities.org/graphql`,
    DOCUMENT_S3_UPLOAD: `https://amo-east1-enc.s3-accelerate.amazonaws.com/`,
    DOCUMENT_IMMUNIZATION: `https://54mkffx395.execute-api.us-east-1.amazonaws.com/v0/download/immunization`,
    SAMPLE_DEPARTURE_GUIDE: `https://s3.amazonaws.com/amo-static-enc/d/Pre-Departure_Guide.pdf`,
    DOCUMENT_SAMPLE_CV: `https://s3.amazonaws.com/amo-static-enc/d/AMO_Sample_CV.pdf`,
    DOCUMENT_UPLOAD_AWS: `https://54mkffx395.execute-api.us-east-1.amazonaws.com/v11052018/upload`,
    COUNTRY_LIST: `https://s3.amazonaws.com/amo-static-enc/json/countries.json`,
    COUNTRY_CODE_LIST: `https://app.amopportunities.org/img/flags/country.json`,
    HELLO_SIGN_API: `https://kwusau0m49.execute-api.us-east-1.amazonaws.com/v1/`,
    HELLO_SIGN_CLIENT_ID: `d7cfcd905e1125412161e1acd981b1b5`,
    CONTENT_URL_JSON: `https://d6fj0ztu4j10m.cloudfront.net/text.json`,
    ENGLISH_ONLY_UPLOAD: `244d3ab2d5m9321o3`,
    BASE_HREF: `https://app.amopportunities.org/`,
    LOG_PROGRAM_VIEW: `https://x29y8g6jk1.execute-api.us-east-1.amazonaws.com/v0/logsearch`,
    ROLLBAR_TOKEN: `be9969e93d914356bfa84b01d34f2aeb`,
    CAPTCHA_KEY: `6LeG774UAAAAABePqpdydvOz9xppWF_0aAHjCM2m`,
    API_GATEWAY_COGNITO_AUTH: `https://auth.amopportunities.org`, // `https://u1pn0i2tke.execute-api.us-west-2.amazonaws.com/prod`,
    COGNTIO_APP_CLIENT_ID: `7tddd49kplsp4bd5omc6gj2kt8`, // krhrjik1sbqghq77uai8qjndd
    COGNITO_USER_POOL: `us-west-2_IHMW3ujt6`,
    MANAGEMENT_APP_CLIENT_ID: `5kkc8h1r37h9rudmg64vshan4c`,
    MANAGEMENT_USER_POOL: `us-west-2_iXnl0ym3o`,
    MANAGEMENT_COGNITO_FLOW_TYPE: `CUSTOM_AUTH`,
    COGNITO_REGION: 'us-west-2',
    COGNITO_FLOW_TYPE: 'USER_SRP_AUTH',
    SERVICE_VERSION: `62ab95121f02ea71429c6ed6854f09555e5c54e4}`,
    STRIPE_KEY: `pk_live_Af7sDQNB3YsQzK9rZZl3hc7R`,
    MODE: `production`,
    API_GATEWAY_PAYMENTS: `https://pay.amopportunities.org`,
    IONIC_VERSION: `ionicversion`,
    RELEASE_VERSION: ``,
    CA_HS_SERVERLESS: 'https://1f0yvyq8zb.execute-api.us-west-2.amazonaws.com/prod/document/clinical-agreement/complete',
    addr: 'DEFAULT',
};
